import Head from 'next/head'
import styles from '@/styles/Home.module.css'
import SearchForm from '@/components/SearchForm'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setClearAllFormData, setClearListData, setFormType } from '@/store/slices/filterSlice'
import { useRouter } from 'next/router'

export default function Home() {
  const router = useRouter();
  const dispatch = useDispatch();
  useEffect(()=>{
    if(!router?.query?.key){
      dispatch(setClearAllFormData())
      dispatch(setFormType("advancefillter"))
    }
    dispatch(setClearListData())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dispatch])
  return (
    <>
      <Head>
        <title>Provider Search - Liberty Medicare Advantage</title>
        <meta name="description" content="Your Hometown Health Plan Located in Wilmington, NC At Liberty Medicare Advantage, our mission is to improve the health and well-being of our members within our community by offering a complete and cost-effective senior care continuum, close to home and family. We combine our Customized Care team, strong relationship with provider community and local resources ...read more..." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      
      <main className={`${styles.main}`}>
        <SearchForm/>
      </main>
    </>
  )
}
