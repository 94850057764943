const FilterIcon = ({color}) => {
   return (
      <svg id="settings-sliders" xmlns="http://www.w3.org/2000/svg" width="27.944" height="27.947" viewBox="0 0 27.944 27.947">
         <path id="Path_577" data-name="Path 577" d="M1.19,5.532H4.376a4.341,4.341,0,0,0,8.377,0H26.806a1.164,1.164,0,1,0,0-2.329H12.753a4.341,4.341,0,0,0-8.377,0H1.19a1.164,1.164,0,0,0,0,2.329Z" transform="translate(-0.026 0)" fill={color?"#ffffff":"#c8c8c8"} />
         <path id="Path_578" data-name="Path 578" d="M26.806,179.183H23.62a4.34,4.34,0,0,0-8.376,0H1.19a1.164,1.164,0,0,0,0,2.329H15.244a4.34,4.34,0,0,0,8.376,0h3.186a1.164,1.164,0,0,0,0-2.329Z" transform="translate(-0.026 -166.374)" fill={color?"#ffffff":"#c8c8c8"} />
         <path id="Path_579" data-name="Path 579" d="M26.806,355.2H12.753a4.341,4.341,0,0,0-8.377,0H1.19a1.164,1.164,0,0,0,0,2.329H4.376a4.341,4.341,0,0,0,8.377,0H26.806a1.164,1.164,0,0,0,0-2.329Z" transform="translate(-0.026 -332.788)" fill={color?"#ffffff":"#c8c8c8"} />
      </svg>
   )
}

export default FilterIcon;