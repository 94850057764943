module.exports = ({
   defaultForm: () => ({
      heading: 'Find Doctors and Locations',
      formFields: [
         {
            type: 'select',
            name: 'selectFor',
            label: 'Search For',
            placeholder: 'Select',
            messages: 'Select doctors or locations.',
            required: true,
            options: [
               {
                  value: 'Doctor',
                  label: 'Doctors',
                  default: false,
               },
               {
                  value: 'Facility',
                  label: 'Locations',
                  default: false,
               }
            ]
         },
         {
            type: 'select',
            name: 'selectCSCType',
            label: 'Search For Type (optional)',
            placeholder: 'Select',
            messages: 'Select county or state or city.',
            required: false,
            options: [
               {
                  value: '',
                  label: 'Select',
                  default: false,
               },
               {
                  value: 'county',
                  label: 'County',
                  default: false,
               },
               {
                  value: 'city',
                  label: 'City',
                  default: false,
               },
               {
                  value: 'zipcode',
                  label: 'Zip Code',
                  default: false,
               },
            ]
         },
         {
            type: 'select',
            name: 'county',
            label: 'County',
            column: 'county',
            placeholder: 'Select',
            messages: '',
            required: false,
            fetchFrom: "countyList",
            options: [
            ],
            refKey: 'selectCSCType'
         },
         {
            type: 'select',
            name: 'city',
            label: 'City',
            column: 'city',
            placeholder: 'Select',
            messages: '',
            required: false,
            fetchFrom: "cityList",
            options: [
            ],
            refKey: 'selectCSCType'
         },

         {
            type: 'select',
            name: 'zipcode',
            column: 'zip_code',
            label: 'ZIP Code (optional)',
            placeholder: 'Select',
            messages: 'Enter a 5-digit ZIP Code',
            required: false,
            fetchFrom: 'zipCodeList',
            options: [
            ],
            refKey: 'selectCSCType'
         },
         {
            type: 'text',
            label: 'Keywords (optional)',
            placeholder: '',
            name: 'keyword',
            messages: 'Enter any keywords, name, facility, or location.',
            required: false
         },
         {
            type: 'submit',
            label: 'Search',
            advanceSearchIcons: true
         }
      ],
      validationSchemeObj: {
         selectFor: "Please select atleast one roles",
      },
      values: {
         selectFor: '',
         zipcode: '',
         keyword: ''
      }
   }),

   advanceFillter: () => ({
      heading: 'Provider Search',
      tabs: [
         {
            heading: 'Provider filters',
            id: "doctorFilters",
            formFields: [
               // {
               //    type: 'text',
               //    label: 'Provider\'s Last Name (optional)',
               //    placeholder: '',
               //    name: 'keyword',
               //    messages: '',
               //    required: false
               // },
               {
                  type: 'text',
                  autoComplete: true,
                  label: 'Provider\'s Last Name',
                  placeholder: '',
                  name: 'lastName',
                  messages: '',
                  required: false,
                  options: [],
               },
               {
                  type: 'text',
                  autoComplete: true,
                  label: 'Provider\'s First Name (optional)',
                  placeholder: '',
                  name: 'firstName',
                  messages: '',
                  required: false,
                  options: [],
               },
               {
                  type: 'select',
                  name: 'county',
                  label: 'County',
                  column: 'county',
                  placeholder: 'Select',
                  messages: '',
                  required: false,
                  fetchFrom: "countyList",
                  options: [
                  ]
               },
               {
                  type: 'select',
                  name: 'state',
                  label: 'State (optional)',
                  column: 'state',
                  placeholder: 'Select',
                  messages: '',
                  required: false,
                  fetchFrom: "stateList",
                  options: [
                  ]
               },
               {
                  type: 'select',
                  name: 'city',
                  column: 'city',
                  label: 'City (optional)',
                  placeholder: 'Select',
                  messages: '',
                  required: false,
                  fetchFrom: "cityList",
                  options: [
                  ]
               },
               {
                  type: 'select',
                  name: 'zipcode',
                  column: 'zip_code',
                  label: 'ZIP Code (optional)',
                  placeholder: 'Select',
                  messages: 'Enter a 5-digit ZIP Code',
                  required: false,
                  fetchFrom: 'zipCodeList',
                  options: [
                  ]
               },
               // {
               //    type: 'select',
               //    name: 'hospital',
               //    label: 'Hospital',
               //    column:'location_name',
               //    placeholder: 'Select',
               //    fetchFrom:"hospitalList",
               //    messages: '',
               //    required: false,
               //    options: [
               //    ]
               // },
               {
                  type: 'select',
                  name: 'specialties',
                  label: 'Speciality (optional)',
                  placeholder: 'Select',
                  column: 'specialty_name',
                  fetchFrom: "specialtiesList",
                  messages: '',
                  required: false,
                  options: [
                  ]
               },
               // {
               //    type: 'select',
               //    name: 'gender',
               //    label: 'Gender',
               //    placeholder: 'Select',
               //    messages: '',
               //    fetchFrom: 'genderList',
               //    column: 'gender',
               //    required: false,
               //    options: [
               //    ]
               // },
               // {
               //    type: 'select',
               //    name: 'languages',
               //    label: 'Languages',
               //    placeholder: 'Select',
               //    column: 'languages',
               //    fetchFrom: 'languagesList',
               //    messages: '',
               //    required: false,
               //    options: [
               //    ]
               // },
               {
                  type: 'submit',
                  label: 'Search',
                  advanceSearchIcons: false,
                  isResetButton: true,
                  resetLabel: 'Clear Filters'
               }
            ]
         },
         {
            heading: 'Facility filters',
            id: 'facilityFilters',
            formFields: [
               {
                  type: 'text',
                  autoComplete: true,
                  label: 'Location Name',
                  placeholder: '',
                  name: 'locationName',
                  messages: '',
                  required: false,
                  options: [],
               },
               {
                  type: 'select',
                  name: 'county',
                  label: 'County',
                  column: 'county',
                  placeholder: 'Select',
                  messages: '',
                  required: false,
                  fetchFrom: "countyList",
                  options: [
                  ]
               },
               {
                  type: 'select',
                  name: 'state',
                  label: 'State (optional)',
                  column: 'state',
                  placeholder: 'Select',
                  messages: '',
                  required: false,
                  fetchFrom: "stateList",
                  options: [
                  ]
               },
               {
                  type: 'select',
                  name: 'city',
                  label: 'City (optional)',
                  column: 'city',
                  placeholder: 'Select',
                  messages: '',
                  required: false,
                  fetchFrom: "cityList",
                  options: [
                  ]
               },
               {
                  type: 'select',
                  name: 'zipcode',
                  column: 'zip_code',
                  label: 'ZIP Code (optional)',
                  placeholder: 'Select',
                  messages: 'Enter a 5-digit ZIP Code',
                  required: false,
                  fetchFrom: 'zipCodeList',
                  options: [
                  ]
               },
               // {
               //    type: 'select',
               //    name: 'hospital',
               //    label: 'Hospital',
               //    column:'location_name',
               //    placeholder: 'Select',
               //    fetchFrom:"hospitalList",
               //    messages: '',
               //    required: false,
               //    options: [
               //    ]
               // },
               // {
               //    type: 'select',
               //    name: 'specialties',
               //    column: 'specialty_name',
               //    label: 'Departments & Specialties',
               //    placeholder: 'Select',
               //    fetchFrom: "specialtiesList",
               //    messages: '',
               //    required: false,
               //    options: [
               //    ]
               // },
               {
                  type: 'submit',
                  label: 'Search',
                  column: '',
                  advanceSearchIcons: false,
                  isResetButton: true,
                  resetLabel: 'Clear'
               }
            ]
         }
      ]
   })
})